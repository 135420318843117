<template>
  <div>
    <en-table-layout :tableData="tableData.data" :loading="loading" :tips="true">
      <!-- 搜索条件 -->
      <div slot="tips" class="TitleBox">
        <div class="conditions">
          <div>核销日期：</div>
          <el-date-picker class="choose-machine" style="width: 200px; margin-right: 20px" v-model="times"
            type="daterange" align="center" :editable="false" unlink-panels size="mini"
            :default-time="['00:00:00', '23:59:59']" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
            value-format="timestamp" :picker-options="{
      disabledDate: MixinDisabledDate,
      shortcuts: MixinPickerShortcuts,
    }" @change="
      times
        ? (params.start = times[0] / 1000)
        : (params.start = '');
    times
      ? (params.end = times[1] / 1000)
      : (params.end = '');
    "></el-date-picker>
        </div>
        <div class="conditions" style="width: 220px; margin-left: 10px">
          <el-select v-model="params.keywords" placeholder="请选择关键字" clearable size="mini"
            @change="params.keywords === '' ? (params.keyvalue = '') : ''">
            <el-option v-for="item in keyTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="conditions" v-if="params.keywords" style="margin-right: 20px">
          <el-input v-model="params.keyvalue" placeholder="请输入关键字内容" class="ipt-default" size="mini"></el-input>
        </div>
        <div class="conditions">
          <el-button @click="searchOrderList" type="primary" class="btn-default" size="mini">搜索</el-button>
        </div>
        <div class="conditions" style="margin-left: 20px">
          <el-button @click="GET_tradeOrdersExport" type="primary" class="btn-default" size="mini">导出</el-button>
        </div>
      </div>

      <template slot="table-columns">
        <!-- 订单编号 -->
        <el-table-column prop="trade_sn" label="订单编号" width="160" fixed="left" />
        <!-- 活动图片 -->
        <el-table-column label="活动图片" align="center" width="100">
          <template slot-scope="scope">
            <div class="imgBox">
              <img :src="scope.row.original" />
            </div>
          </template>
        </el-table-column>
        <!-- 活动名称 -->
        <el-table-column label="活动名称" width="180" prop="name" show-overflow-tooltip />
        <!-- 活动编号 -->
        <el-table-column prop="sku_sn" label="活动编号" width="140" show-overflow-tooltip />
        <!-- 商家名称 -->
        <el-table-column prop="seller_name" label="商家名称" width="180" show-overflow-tooltip />
        <!-- 下单用户手机号 -->
        <el-table-column prop="member_mobile" label="下单用户手机号" width="120" />
        <!-- 核销人手机号 -->
        <el-table-column prop="verify_member_mobile" label="核销人手机号" width="120" />
        <!-- 核销编码 -->
        <el-table-column prop="verify_code" label="核销编码" width="120" />
        <!-- 核销时间 -->
        <el-table-column prop="create_time" :formatter="MixinUnixToDate" label="核销时间" width="160" />
      </template>
      <el-pagination v-if="tableData.data" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size" layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_orderList from "@/api/orderList";
import * as Export2Excel from "@/utils/exportXlsx";

export default {
  name: "activityList",
  data () {
    return {
      //  列表loading状态
      loading: false,

      // 列表参数
      params: {
        page_no: 1,
        page_size: 10,
        start: null,
        end: null,
        keywords: null,
        keyvalue: null,
        order_status: "0",
      },

      //  列表数据
      tableData: [],

      // 查询时间
      times: [],

      // 关键字类型搜索
      keyTypeList: [
        { id: "trade_sn", name: "订单编号" },
        { id: "member_mobile", name: "客户手机号" },
        { id: "name", name: "活动名称" },
        { id: "sku_sn", name: "活动编号" },
        { id: "verify_code", name: "电子券码" },
        { id: "seller_name", name: "商户名称" },
      ],
    };
  },
  watch: {
    $route () {
      this.params.page_no = 1;
      this.params.page_size = 10;
      this.params.start = null;
      this.params.end = null;
      this.params.keywords = null;
      this.params.keyvalue = null;
      this.params.order_status = '0';
      this.times = [];
      // 获取订单列表
      this.GET_tradeOrders();
    },
  },
  mounted () {
    // 获取订单列表
    this.GET_tradeOrders();
  },
  activated () {
    // 获取订单列表
    this.GET_tradeOrders();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_tradeOrders();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_tradeOrders();
    },

    /** 搜索订单列表 */
    searchOrderList () {
      this.params.page_no = 1
      this.GET_tradeOrders()
    },

    /** 获取订单列表 */
    GET_tradeOrders () {
      var params = {};
      if (this.params.keywords) {
        params[this.params.keywords] = this.params.keyvalue;
      }
      if (this.params.start) {
        params.start = this.params.start;
        params.end = this.params.end;
      }
      params.page_no = this.params.page_no;
      params.page_size = this.params.page_size;
      API_orderList.getVerifyList(params).then((res) => {
        this.tableData = res;
        console.log(res);
      });
    },

    /** 导出核销列表 */
    GET_tradeOrdersExport () {
      var params = {};
      if (this.params.keywords) {
        params[this.params.keywords] = this.params.keyvalue;
      }
      if (this.params.start) {
        params.start = this.params.start;
        params.end = this.params.end;
      }
      params.page_no = 1;
      params.page_size = 99999;
      const th = [
        "订单编号",
        "活动名称",
        "活动编号",
        "商家名称",
        "下单用户手机号",
        "核销人手机号",
        "核销编码",
        "核销时间"
      ];
      API_orderList.getVerifyList(params).then((res) => {
        var excelData = [];
        res.data.forEach((item) => {
          var excelDataRow = [];
          excelDataRow.push(item.trade_sn)
          excelDataRow.push(item.name);
          excelDataRow.push(item.sku_sn);
          excelDataRow.push(item.seller_name);
          excelDataRow.push(item.member_mobile);
          excelDataRow.push(item.verify_member_mobile);
          excelDataRow.push(item.verify_code);
          console.log(item.create_time);
          excelDataRow.push(this.MixinUnixToDate('', '', item.create_time));
          excelData.push(excelDataRow);
        });
        Export2Excel.export_json_to_excel(
          th,
          excelData,
          "核销列表数据"
        );
      });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
::v-deep .el-table td:not(.is-left) {
  text-align: center;
}

.TitleBox {
  display: flex;

  .conditions {
    display: flex;
    align-items: center;

    div {
      font-size: 15px;
      font-weight: 500;
      color: rgba(68, 68, 68, 1);
    }

    .choose-machine {
      width: 60%;
    }
  }
}

.imgBox {
  display: flex;
  align-items: center;

  img {
    width: 64px;
    height: 48px;
    margin-right: 10px;
  }

  div {
    width: 150px;
    font-size: 16px;
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.buttonBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

::v-deep .toolbar {
  padding-bottom: 0 !important;
}

.elTabBox {
  ::v-deep .el-tabs__item {
    background: #fff;
  }

  ::v-deep .is-active {
    background: #008080;
    color: #fff;
  }

  ::v-deep #tab-first {
    border-radius: 4px 0 0 4px;
  }

  ::v-deep #tab-second {
    border-radius: 0 4px 4px 0;
  }
}
</style>
